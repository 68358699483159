.banner{
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.banner video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    object-fit: cover;
}
.banner h1{
    margin: 0;
    position: absolute;
    top : 0;
    left: 0;
    width : 100%;
    height:90%;
    background: #ffffff;
    color: #000;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 9vw;
    text-align: center;
    text-transform: uppercase;
    line-height: 70vh;
    mix-blend-mode:screen;
}
.para{
    color: #000;
    position: absolute;
    align-items: center;
    text-align: center;
    padding: 30px;
}
.com{
    align-items: center;
    text-align: center;
}