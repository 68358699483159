

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fbfbfc;
}


.navbar{
  background-color: #f56e28;
  padding: 0;
  margin: 0;
  top: 0;
  position: fixed;
  z-index: 2;
  width: 100%;

}

.logo {
  padding: 10px;
  color: white;
}



.coming-soon {
  color: #f56e28;
  margin-top: 100px;
}

.social-media-container {
  margin: 20px 0;
}

.social-media-icon {
  margin: 0 10px;
  color: #f56e28;
  font-size: 24px;
  text-decoration: none;
  transition: color 0.3s;
  z-index: 2;
}

.social-media-icon:hover {
  
  color: #46b450;

}

.contact-email {
  margin: 20px 0;
  color: #23282d;

} 
.com{
  z-index: 1;
  margin-top: 200px;
}
